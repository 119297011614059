
.parentInfoWrapper {
    width: 100%;
    margin-bottom: 24px;
}

.groupedInfoWrapper {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

}

.modelDot {
    border-radius: 50%;
    background-color: rgb(241, 222, 18);
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.canvaWrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}