.heart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  font-family: system-ui, -apple-system, sans-serif;
}

.heart {
  position: relative;
}


/* Simple Pulse */
.heart-simple {
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* Heartbeat Effect */
.heart-realistic {
  animation: heartbeat 1.3s ease-in-out infinite;
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(0.95);
  }
  30% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
}

/* Smooth Pulse with Glow */
.heart-glow {
  animation: smoothPulse 2s ease-in-out infinite;
}

@keyframes smoothPulse {
  0%,
  100% {
    transform: rotate(-45deg) scale(1);
    filter: drop-shadow(0 0 0px rgba(255, 0, 0, 0.5));
  }
  50% {
    transform: rotate(-45deg) scale(1.1);
    filter: drop-shadow(0 0 10px rgba(255, 0, 0, 0.7));
  }
}

h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.heart-container {
  text-align: center;
}
