.thumbnailWrapper {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }

    .caption {
        margin-left: 16px;
        font-weight: 500;
        color: #5bb5d5;
    }
}

.pdfReportHeaderWrapper {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.loadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
