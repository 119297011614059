.form {
  display: flex;
  flex-direction: column;
  max-width: 980px;
  margin: 0 auto;
  
  padding: 32px;
  border-top: 1px solid #32444d;
  border-radius: 8px;
  background-color: rgba(33, 43, 47, 0.8);
  color: #f1f4f6;
}

.title {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  padding: 0 0 5px;
}

.mb10 {
  margin-bottom: 10px;
  margin-right: 5px;
  width: auto;
}

.submitBtn {
  width: 200px;
  display: flex;
  justify-content: space-between;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.whiteContainer {
  border: 1px solid #fff;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  min-height: 90px;

  /* background-color:#e6f0fe;
  background-color:black; */
  border: 1px solid #32444d;
}

.listDicoms {
  margin: 0;
  padding: 0;
}

.listItemDicoms {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.scansItem {
  /* border: 1px solid #fff; */
  padding: 8px;
  position: relative;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #32444d;
  color: #f1f4f6;
}

.scansView {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #fff;
  /* border: 1px solid #fff; */
  padding: 2px 5px;
  border-radius: 5px;
  /* text-decoration: none; */
  
  margin-right: 0px;
  border-radius: 3px;
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  -webkit-transition: all 300ms ease, box-shadow 200ms ease, background-color 200ms ease;
  transition: all 300ms ease, box-shadow 200ms ease, background-color 200ms ease;
  font-family: Pulpdisplay, sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 6px 12px;
  text-transform: none;
}

.scansView:hover, .scansView:focus {
  /* background: rgba(255,255,255, .1); */
}

.scansBottom {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 300px;
  margin-left: auto;
}

.scanBtn {
  color: #fff;
  /* border: 1px solid #fff;*/

  /* background-color: #32444d;
  color: #f1f4f6; */
  /* min-width: 150px; */
  margin-right: 0px;
  border-radius: 3px;
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  -webkit-transition: all 300ms ease, box-shadow 200ms ease, background-color 200ms ease;
  transition: all 300ms ease, box-shadow 200ms ease, background-color 200ms ease;
  font-family: Pulpdisplay, sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 6px 12px;
  text-transform: none;
}

.scanBtn:hover, .scanBtn:focus {
  /* border: 1px solid #fff;
  background: rgba(255,255,255, .1); */
}

.inProgress {
  color: #dbb83d;
}

.complete {
  color: #2ea111;
}

.pending {
  color: #dbb83d;
}

.scansFooter {
  display: flex;
  justify-content: space-between;
}

.studyWrapper {
  min-height: 300px;
  position: relative;
}

.dicomsWrapper {
  min-height: 100px;
  position: relative;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
